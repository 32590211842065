<template>
  <div id="app">
    <div class="md-layout md-alignment-bottom-center top-menu">
      <div class="md-layout-item md-size-40" style="text-align: left;">
        <img width="100px" alt="HBUHSD Covid Stats" src="./assets/logo.png">
      </div>
      <div class="md-layout-item md-size-40" style="text-align: right;">
        <h1>HBUHSD Covid Stats</h1>
      </div>
    </div>
    <div class="md-layout md-alignment-top-center">
      <div class="md-layout-item md-size-80" style="text-align: left;">
        <p>This dashboard has been discontinued until further notice. Please contact HR if you have any questions.</p>
      </div>
    </div>
        </div>
</template>

<script>
export default {
  name: 'app',
  mounted: function() {
    var self = this
    this.$http.get('/stats.js')
      .then(function(response) {
        console.log(response.data)
        self.stats = response.data['Data']
        self.updated = response.data['Update']
    })
  },
  data: function () {
    return {
      stats: {
        updated: '',
        rows: []
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
  padding-bottom: 60px;
}
.top-menu {
  margin-bottom: 10px;
}
.top-menu h1 {
  margin: unset;
}
.top-menu th {
  text-align: center;
}
</style>
